.landing-page {
    background-color: $light-bg;
    height: 100vh;
}

.landing__bg-dark {
    background: $dark-background;
}

.landing__bg-light {
    background-color: $light-bg;
}


body {
    overflow-x: hidden !important;
  }
  @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600&display=swap');
  
  
  .landing__navbar {
    background: $dark-background;
  }
  
  
  .landing__navbar-link {
    text-decoration: none !important;
    color: white;
  }
  
  .landing__hero {
    background: $dark-background;
    color: white;
  }

  .position-static {
      position: static !important;
  }
  
  .landing__hero-content {
  
    max-width: 620px;
  }
  
  .landing__hero-content h1 {
  
    font-family: "IBM Plex Sans", sans-serif;
    color: white !important;
  }
  
  .landing__hero-content p {
  
    font-family: Heebo, sans-serif;
    color: #95a1bc;
  }

  .customActiveTab{
    border: none !important;
    border-bottom: solid !important;
    border-radius: 0 !important;
    border-color: #4055D4 !important;
    cursor: pointer;
  }
  
  .landing__features p {
    font-family: Heebo, sans-serif;
    font-size: 1.2rem;
    line-height: 30px;
    color: #58678c;
    font-weight: 400;
  }
  
  
  .landing__features-heading {
    font-family: "IBM Plex Sans", sans-serif;
    color: #0b0d22;
    font-weight: 500;
    font-size: 2rem;
    line-height: 3rem;
  }
  
  .landing__features-content {
    color: #58678c;
    line-height: 2rem;
    font-size: 1.1rem;
    font-weight: 400;
  }
  
  .divider {
    margin-top: 5rem !important;
    border-color: #d9e0f0 !important; 
  }
  
  .landing__page-Funds {
    /* // display: flex; */
    overflow: auto;
    white-space: nowrap;
    color: white; 
    min-height: 300px;
     /* background-color: blanchedalmond; */
  }
  
  .landing__page-Fund:nth-child(1) {
    margin-left: 20px;
  }
  
  .landing__page-Fund {
    /* // padding: 20px 10px; */
    display: inline-block;
     background-color: #4C4C78;
     height: 200px;
     /* margin: 0 auto; */
    width: 35%;
    margin-top: 20px;
    margin-right: 10px;
    color: white; 
    text-align: center;
    /* // box-shadow: 2px 2px 5px grey; */
    border-radius: 10px;
    transition: transform .2s;
  }
  
  .landing__page-Fund:hover { 
    transform: scale(1.1);
  }
  
  .landing__page-Fund-img {
    padding-top: 20px;
  }
  
  .landing__page-Fund-img img {
    width: 100%;
    height: auto;
  }
  
  .performance{
  
  }
  .performance p {
    font-size: 0.875em;
    display: inline-block;
  }
  
  /* 
  @media (max-width: 450px) {
    .Fund {
      // margin: 0 auto;
      width: 85%;
    }
  
    .Fund:hover { 
      transform: none;
    }
  } */
  
  .landing__roadmap-heading {
    padding-top: 4rem;
    background-color:  #0b0d22;
    color: white;
  }
  
  
  .landing__roadmap-heading h1 {
    line-height: 2rem;
    font-size: rem;
    font-weight: 500;
    font-family: "IBM Plex Sans", sans-serif;
  }
  
  
  .landing__roadmap-heading p {
    color: #95a1bc;
    line-height: 2rem;
    font-size: rem;
    font-weight: 400;
    font-family: Heebo, sans-serif;
  }
  /* html, body {
    font-family: 'Open Sans', sans-serif;
  } */
  
  .cd-container {
    /* width: 90%; */
    /* max-width: 1080px; */
    /* margin: 0 auto; */
    background: #0b0d22;
    padding: 0 10%;
    border-radius: 2px;
  }
  
  .cd-container::after {
    content: '';
    display: table;
    clear: both;
  }
  
  /* -------------------------------- 
  
  Main components 
  
  -------------------------------- */
  #cd-timeline {
    position: relative;
    padding: 2em 0;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  
  #cd-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 25px;
    height: 100%;
    width: 4px;
    background: #7E57C2;
  }
  
  @media only screen and (min-width: 1170px) {
    #cd-timeline {
      margin-top: 3em;
      margin-bottom: 3em;
    }
  
    #cd-timeline::before {
      left: 50%;
      margin-left: -2px;
    }
  }
  .cd-timeline-block {
    position: relative;
    margin: 2em 0;
  }
  
  .cd-timeline-block:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
  
  @media only screen and (min-width: 1170px) {
    .cd-timeline-block {
      margin: 4em 0;
    }
  
    .cd-timeline-block:first-child {
      margin-top: 0;
    }
  
    .cd-timeline-block:last-child {
      margin-bottom: 0;
    }
  }
  .cd-timeline-img {
    position: absolute;
    top: 8px;
    left: 12px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px #7E57C2, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
  }
  
  .cd-timeline-img {
    background: #673AB7;
  }
  
  @media only screen and (min-width: 1170px) {
    .cd-timeline-img {
      width: 30px;
      height: 30px;
      left: 50%;
      margin-left: -15px;
      margin-top: 15px;
      /* Force Hardware Acceleration in WebKit */
      -webkit-transform: translateZ(0);
      -webkit-backface-visibility: hidden;
    }
  }
  .cd-timeline-content {
    position: relative;
    margin-left: 60px;
    margin-right: 30px;
    background: #3D3D73;
    border-radius: 2px;
    padding: 1em;
  }
  .cd-timeline-content .timeline-content-info {
    background: #0b0d22;
    padding: 5px 10px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.08);
    border-radius: 2px;
  }
  .cd-timeline-content .timeline-content-info i {
    margin-right: 5px;
  }
  .cd-timeline-content .timeline-content-info .timeline-content-info-title, .cd-timeline-content .timeline-content-info .timeline-content-info-date {
    width: calc(50% - 2px);
    display: inline-block;
  }
  @media (max-width: 500px) {
    .cd-timeline-content .timeline-content-info .timeline-content-info-title, .cd-timeline-content .timeline-content-info .timeline-content-info-date {
      display: block;
      width: 100%;
    }
  }
  .cd-timeline-content .content-skills {
    font-size: 12px;
    padding: 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .cd-timeline-content .content-skills li {
    background: #40484D;
    border-radius: 2px;
    display: inline-block;
    padding: 2px 10px;
    color: rgba(255, 255, 255, 0.7);
    margin: 3px 2px;
    text-align: center;
    flex-grow: 1;
  }
  
  .cd-timeline-content:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .cd-timeline-content h2 {
    color: rgba(255, 255, 255, 0.9);
    margin-top: 0;
    margin-bottom: 5px;
  }
  
  .cd-timeline-content p, .cd-timeline-content .cd-date {
    color: rgba(255, 255, 255, 0.7);
    font-size: 13px;
    font-size: 0.8125rem;
  }
  
  .cd-timeline-content .cd-date {
    display: inline-block;
  }
  
  .cd-timeline-content p {
    margin: 1em 0;
    line-height: 1.6;
  }
  
  .cd-timeline-content::before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid #333C42;
  }
  
  @media only screen and (min-width: 768px) {
    .cd-timeline-content h2 {
      font-size: 20px;
      font-size: 1.25rem;
    }
  
    .cd-timeline-content p {
      font-size: 16px;
      font-size: 1rem;
    }
  
    .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
      font-size: 14px;
      font-size: 0.875rem;
    }
  }
  @media only screen and (min-width: 1170px) {
    .cd-timeline-content {
      color: white;
      margin-left: 0;
      padding: 1.6em;
      width: 36%;
      margin: 0 10%;
    }
  
    .cd-timeline-content::before {
      top: 24px;
      left: 100%;
      border-color: transparent;
      border-left-color: #333C42;
    }
  
    /* .cd-timeline-content .cd-date {
      position: absolute;
      width: 100%;
      left: 122%;
      top: 6px;
      font-size: 16px;
      font-size: 1rem;
    } */
  
    .cd-timeline-block:nth-child(even) .cd-timeline-content {
      float: right;
    }
  
    .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
      top: 24px;
      left: auto;
      right: 100%;
      border-color: transparent;
      border-right-color: #333C42;
    }
  
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
      float: right;
    }
  
    .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
      left: auto;
      right: 150%;
      top: 10%;
      text-align: right;
    }
  }

  .hidden {
    display: none !important;
  }
  