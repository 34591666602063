@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

.swap {
    // white-space: nowrap;
    h1,
    h2,
    h3,
    h4,
    p,
    a {
        color: #fff;
    }
    h2 {
        font-weight: 300;
        font-size: 17px;
    }
    .chart-part {
        padding: 30px 0 0 0;
    }
    .chart-form {
        background: #151839;
        padding: 20px 20px;
        position: relative;
        overflow: hidden;

        .nav {
            flex-wrap: nowrap;
        }
    }
    #tab1 {
        border-bottom: 1px solid #bdb7b752;
    }
    #tab1 li a {
        color: #d3d3d3;
        font-weight: 300;
        font-size: 17px;
        padding: 5px 0px;
        margin-right: 25px;
    }
    #tab1 li a.active {
        color: #fff;
        background: none;
        font-weight: 400;
        font-size: 18px;
    }
    #tab1-content {
    }
    .receive {
        background: #101334;
        padding: 38px 10px;
        margin-top: -16px;
        white-space: nowrap;
        text-align: center;
    }

    .receive h5 {
        text-align: left;
    }
    div#tab1-market h2 a {
        text-decoration: none;
        color: #fff;
    }
    div#tab1-market h2 a span {
        margin-left: 25px;
    }
    div#tab1-market h2 {
        margin-bottom: 20px;
    }
    .receive div h2 {
        display: inline-block;
        cursor: pointer;
        font-weight: 600;
    }
    .receive div input {
        display: inline-block;
        border: 1px solid #2630b2;
        width: 10rem;
        outline: none;
        border-radius: 30px;
        padding: 0.8rem;
        background: #101334;
        color: #d3d3d3;
        margin-left: 2rem;
    }
    .receive div img {
        width: 1.6rem;
        margin-right: 0.5rem;
        height: auto;
    }
    .receive div button {
        background: #b409d4;
        color: #fff;
        border: none;
        padding: 0.5rem 1rem;
        display: block;
        border-radius: 30px;
        outline: none;
        min-width: 120px;
        margin: 0 auto;
        margin-top: 18px;

        &.disabled,
        &:disabled {
            opacity: $btn-disabled-opacity;
            @include box-shadow(none);
            cursor: not-allowed;
        }

        &:hover {
            transform: translateY(-1px);
            -webkit-transform: translateY(-1px);
        }
    }

    .receive p {
        text-align: center;
        font-size: 0.8rem;
        color: rgb(165, 164, 164);
        font-weight: 600;
    }

    .max-balance {
        cursor: pointer;
        margin-top: 1rem;
    }

    .receive div button {
        cursor: pointer;
    }

    .receive .longShort {
        display: flex;
    }

    .long {
        background-color: #2d9b7f !important;
        font-weight: bold;
    }

    .long:hover {
        background-color: #1cc198 !important;
    }

    .short {
        font-weight: bold;
        background-color: rgb(207, 94, 94) !important;
    }

    .short:hover {
        background-color: #f66 !important;
    }

    .creatAccount {
        background-color: #b409d4;
    }

    ul#tab2 li {
        margin: 0 20px;
    }
    ul#tab2 li a {
        color: #707070;
        font-size: 20px;
    }
    ul#tab2 li a.active {
        background: none;
        border-bottom: 4px solid #4055d4;
        border-radius: 0;
        color: #fff;
    }

    .btns-top div {
        display: flex;
    }

    .btns-top div {
        display: flex;
    }

    .btns-top input {
        width: 100%;
        border-radius: 30px;
        padding: 0.3rem;
    }

    .btns-top input:focus {
        outline: none;
    }

    .btns-top h2 {
        display: inline-block;
        font-weight: 600;
        cursor: pointer;
    }
    .btns-top span {
        font-size: 20px;
        display: inline-block;
        color: #fff;
        margin-left: auto;
        cursor: pointer;
    }
    .btns-btm ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .btns-btm ul li {
        box-sizing: border-box;
        display: flex;
        padding: 1rem;
        align-items: center;
        transition: transform 0.2s;
    }

    .btns-btm ul li:hover {
        // padding: 1rem;
        transform: scale(1.1);
    }

    .btns-btm ul li img {
        width: 1.6rem;
        margin-right: 0.5rem;
        height: auto;
    }

    .btns-btm ul li p {
        margin: 0;
        cursor: pointer;
    }

    .btn-inner-div {
        position: absolute;
        /* visibility: hidden; */
        left: 0;
        top: 0;
        background: #000;
        width: 100%;
        /* transition: all 0.4s linear; */
        height: 100%;
        padding: 30px;
        overflow-y: scroll;
        /* transform: translateY(100%); */
        /* -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%); */
    }
    div#tab1-limit a {
        color: #fff;
        text-decoration: none;
    }

    .firstTokenField {
         white-space: nowrap;
         text-align: center;
    }

    .firstTokenField h5 {
        text-align: left;
    }

    .firstTokenField img {
        width: 1.6rem;
        margin-right: 0.5rem;
        height: auto;
    }

    .firstTokenField h2 {
        display: inline-block;
        font-weight: 600;
        cursor: pointer;
    }

    .firstTokenField input {
        display: inline-block;
        border: 1px solid #2630b2;
        width: 10rem;
        outline: none;
        border-radius: 30px;
        padding: 0.8rem;
        background: #101334;
        color: #d3d3d3;
        margin-left: 2rem;
    }

    .firstTokenField p {
        text-align: center;
        font-size: 0.8rem;
        color: rgb(165, 164, 164);
        font-weight: 600;
        cursor: pointer;
    }

    .fadeIn {
        animation: fade-in-keyframes 1s;
    }
    @keyframes fade-in-keyframes {
        from {
            opacity: 0;
            transform: translateY(100%);
            visibility: visible;
        }
        to {
            opacity: 1;
            transform: translateY(0%);
            visibility: hidden;
        }
    }

    .fadeOut {
        animation: fade-out-keyframes 1s;
        visibility: hidden;
    }
    @keyframes fade-out-keyframes {
        from {
            opacity: 1;
            transform: translateY(0%);
            visibility: visible;
        }
        to {
            opacity: 0;
            transform: translateY(100%);
            visibility: hidden;
        }
    }

    #inner-tab-show {
        margin-left: 1rem;
    }

    .leverageSlider {
        -webkit-appearance: none;
        background-color: black;
    }

    @media only screen and (max-width: 1110px) {
        .receive h5 {
            text-align: center;
        }

        .firstTokenField h5 {
            text-align: center;
        }

        .firstTokenField p {
            text-align: center;
        }

        .receive p {
            text-align: center;
        }
    }
}
