.btn-1 {
  border-color: #3f51b5;
}
.btn-1:hover {
  box-shadow: 5px 5px 10px #12131c, -5px -5px 10px #3c3f5e;
}

.custom-btn {
  padding: 10px 25px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: #27293D;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border-color: #3f51b5;
}

button {
  margin: 20px;
  outline: none;
  color: white;
  border-color: #3f51b5;
  border-radius: 10px;
}
