.blue {
    background-color: $blue;
  }
  .blue-contrast {
    background-color:$blue-contrast;
  }
  .violet{
    background-color: $violet;
  }
  .cerulean {
    background-color: $cerulean;
  }
  .sea-green{
    background-color: $sea-green;
  }
  .gray {
    background-color: #bababa;
  }
  .green {
    background-color: #7ee768;
  }
  .orange {
    background-color: rgba(230, 184, 71, 0.92);
  }
  .black {
    background-color: #1b1b1b;
  }
  .theme-primary {
    background-color: $theme-primary;
  }
  .primary-light {
    background-color: #1F2354;
  }

  .MuiStep-root{
      
        // color:$white;
        // background-color: $transparent-bg;
          &.MuiStep-horizontal{
    // color:$white;
    // background-color: $transparent-bg;
    .MuiStepLabel-label {
      color: $white;
      &:active {
        color: $blue;
      }
  }
    
 }

// .MuiStepLabel-label {
//       color: $white;
//       &:active {
//           color: $blue;
//       }
//   }

}


