.white-content {
  background: $light-bg;



  .MuiStep-root{
      
    // color:$white;
    // background-color: $transparent-bg;
      &.MuiStep-horizontal{
// color:$white;
// background-color: $transparent-bg;
.MuiStepLabel-label {
  color: $black;
  &:active {
    color: $blue;
  }
}

}}




  .navbar.navbar-transparent .navbar-brand {
    color: $black-states;
  }
  .tradeTable {
    background-color: white !important;

    .MuiPaper-root {
      background-color: white !important;
    }
    .MuiTableCell-root {
      background-color: white !important;
      color: black;
    }
    .MuiTableCell-body {
      background-color: white !important;
      color: black;
    }
    .MuiInputBase-root {
      color: black;
    }
    .MuiIcon-root {
      color: black;
    }
    .MuiTypography-root {
      color: black;
    }
    .MuiTable-root {
      color: black;
    }
  }

  .navbar.navbar-transparent .navbar-toggler-bar {
    background: $black-states;
  }
  .navbar.navbar-transparent .navbar-nav li a:not(.dropdown-item) {
    color: $black-states;
    & i {
      color: $black-states;
    }
  }

  .navbar.navbar-transparent .navbar-minimize button i {
    color: $black-states;
  }

  .navbar.navbar-transparent .search-bar.input-group i {
    color: $black-states;
  }
  .navbar.navbar-transparent .search-bar.input-group .form-control {
    color: $default;
    &::placeholder {
      color: $dark-gray;
    }
  }

  .sidebar {
    box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1),
      0 4px 20px 0 rgba(0, 0, 0, 0.15);
    p {
      color: $opacity-8;
    }
  }

  .main-panel {
    background: $light-bg;
  }

  //   .tradeTable{
  //     background-color: black !important;

  //     .MuiPaper-root {
  //         background-color: white !important;
  //     }
  // }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol li,
  ul li,
  pre {
    color: $black-states;
  }

  .font-icon-detail i {
    color: $black;
  }

  .btn:not([data-action]):hover {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }

  .btn-neutral.btn-link {
    color: rgba($black, 0.7);
  }

  //style for inputs

  @include form-control-placeholder(rgba($black, 0.4), 1);
  .has-danger {
    .form-control,
    .input-group-prepend .input-group-text {
      border-color: $danger-states;
    }
  }

  .input-group-prepend .input-group-text {
    border-color: rgba($black-states, 0.5);
    color: $black-states;
  }

  .form-control {
    color: $black;
    border-color: rgba($black-states, 0.5);
    &:focus {
      border-color: $primary;
    }
  }

  .form-group.no-border,
  .input-group.no-border {
    .form-control,
    .form-control + .input-group-prepend .input-group-text,
    .form-control + .input-group-append .input-group-text,
    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text {
      background-color: $opacity-gray-3;
      &:focus,
      &:active,
      &:active {
        background-color: $opacity-gray-5;
      }
    }

    .form-control {
      &:focus {
        & + .input-group-prepend .input-group-text,
        & + .input-group-append .input-group-text {
          background-color: $transparent-bg;
        }
      }
    }
  }

  .input-group[disabled] {
    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text {
      background-color: $black;
    }
  }

  .form-control[disabled],
  .form-control[readonly],
  fieldset[disabled] .form-control {
    background: $light-gray;
    border-color: rgba($black-states, 0.3);
  }

  .input-group-focus {
    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text,
    .form-control {
      border-color: $primary;
    }

    &.no-border {
      .input-group-prepend .input-group-text,
      .input-group-append .input-group-text {
        background-color: $opacity-gray-5;
      }
    }
  }
  .input-group-prepend .input-group-text {
    border-right: none;
  }

  .input-group-append .input-group-text {
    border-left: none;
  }

  .has-danger .form-control:focus,
  .has-success.input-group-focus .input-group-append .input-group-text,
  .has-success.input-group-focus .input-group-prepend .input-group-text {
    border-color: $danger-states;
  }

  .has-success .form-control:focus,
  .has-success.input-group-focus .input-group-append .input-group-text,
  .has-success.input-group-focus .input-group-prepend .input-group-text {
    border-color: darken($success, 10%);
  }

  .btn.btn-link {
    &:hover,
    &:active,
    &:focus {
      color: $dark-gray !important;
    }
  }

  .btn-group .btn.active {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }

  .card:not(.card-white) {
    background: $white;
    box-shadow: 0 1px 15px 0 rgba(123, 123, 123, 0.05);
    .card-header {
      color: $black;
      a[data-toggle="collapse"] {
        color: $black;
      }
    }
    .card-header .card-title,
    .card-body .card-title {
      color: $black-states;
    }

    .card-body {
      .card-category,
      .card-description {
        color: $black-states;
      }
    }

    label:not(.btn) {
      color: $default;
    }

    &.nav-pills .nav-item .nav-link {
      color: $nav-gray;
      background-color: hsla(0, 0%, 87%, 0.3);
      &:not(.active):hover {
        background: hsla(0, 0%, 87%, 0.5);
      }
      &.active {
        color: $white;
      }
    }

    .tab-content .tab-pane {
      color: $nav-gray;
    }

    .card {
      box-shadow: none;
    }

    &.card-plain {
      background: $transparent-bg;
      box-shadow: none;
    }

    &.card-tasks {
      .card-body {
        i {
          color: rgba(34, 42, 66, 0.7);
          &:hover {
            color: $black;
          }
        }
      }
    }
  }

  .table {
    > tbody > tr > td {
      color: rgba($black, 0.7) !important;
    }

    > thead > tr > th,
    > tbody > tr > th,
    > tfoot > tr > th,
    > thead > tr > td,
    > tbody > tr > td,
    > tfoot > tr > td {
      border-color: rgba($black, 0.2);
      padding: 12px 7px;
      vertical-align: middle;
    }

    > thead > tr > th,
    button.btn-neutral.btn-link {
      color: rgba($black, 0.7);
    }
  }

  .footer ul li a {
    color: $black-states;
  }

  .footer .copyright {
    color: $black-states;
  }

  .progress-container,
  .progress-container.progress.sm {
    .progress {
      background: rgba($black, 0.1);
      box-shadow: 0 0 0 3px rgba($black, 0.1);
      .progress-value {
        color: $default;
      }
    }

    .progress-badge {
      color: $black-states;
    }
  }

  .full-page {
    background: $light-bg;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ol li,
    ul li,
    pre {
      color: $black-states;
    }

    .description {
      color: $dark-gray;
    }

    .footer ul li a {
      color: $black-states;
    }

    .footer .copyright {
      color: $black-states;
    }
  }

  .nav-pills .nav-item .nav-link:not(.active) {
    color: $white;
    &:hover {
      background: darken($blue, 20%);
    }
  }
  .button-color {
    border-color: aqua;
  }
  .modal-content {
    border: 0;
    background-color: $white;
    border-top: 3px solid $violet;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ol li,
    ul li,
    pre {
      color: $black-states;
    }
  
    // Modal header
    // Top section of the modal w/ title and dismiss
  
 
  
    // Modal body
    // Where all modal content resides (sibling of .modal-header and .modal-footer)
    .modal-body {
      line-height: 1.9;
  
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ol li,
    ul li,
    pre {
      color: $black-states;
    }
    }
    // Footer (for actions)
    .modal-footer {
      border-top: 0;
      -webkit-justify-content: space-between; /* Safari 6.1+ */
      justify-content: space-between;
  
      button {
        margin: 0;
        padding-left: 16px;
        padding-right: 16px;
        width: auto;
  
        &.pull-left {
          padding-left: 5px;
          padding-right: 5px;
          position: relative;
          left: -5px;
        }
      }
  
    }
    .modal-body + .modal-footer {
      padding-top: 0;
    }
  }

}
