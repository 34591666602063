// @import './assets/css/nucleo-icons.scss';
@import './assets/css/GridView.scss';
@import './assets/demo/demo.scss';
@import './assets/scss/black-dashboard-react.scss';
@import './assets/scss/react-grid-layout-styles.scss';
@import './assets/scss/react-resizable-styles.scss';

@import './scss/button.scss';
@import './scss/customAreaChart.scss';

body {
  background-color: grey;
}
.investinDepositModal .modal-header {
  border: none;
}