@import "black-dashboard-react/custom/functions";
@import "black-dashboard-react/bootstrap/functions";

@import "black-dashboard-react/custom/variables";
@import "black-dashboard-react/bootstrap/variables";

@import "black-dashboard-react/custom/mixins";
@import "black-dashboard-react/bootstrap/mixins";

// Bootstrap components

@import "black-dashboard-react/bootstrap/root";
@import "black-dashboard-react/bootstrap/reboot";
@import "black-dashboard-react/bootstrap/type";
@import "black-dashboard-react/bootstrap/images";
@import "black-dashboard-react/bootstrap/code";
@import "black-dashboard-react/bootstrap/grid";
@import "black-dashboard-react/bootstrap/tables";
@import "black-dashboard-react/bootstrap/forms";
@import "black-dashboard-react/bootstrap/buttons";
@import "black-dashboard-react/bootstrap/transitions";
@import "black-dashboard-react/bootstrap/dropdown";
@import "black-dashboard-react/bootstrap/button-group";
@import "black-dashboard-react/bootstrap/input-group";
@import "black-dashboard-react/bootstrap/custom-forms";
@import "black-dashboard-react/bootstrap/nav";
@import "black-dashboard-react/bootstrap/navbar";
@import "black-dashboard-react/bootstrap/card";
@import "black-dashboard-react/bootstrap/breadcrumb";
@import "black-dashboard-react/bootstrap/pagination";
@import "black-dashboard-react/custom/tabs.scss";
@import "black-dashboard-react/custom/pills.scss";
@import "black-dashboard-react/bootstrap/badge";
@import "black-dashboard-react/bootstrap/jumbotron";
@import "black-dashboard-react/bootstrap/alert";
@import "black-dashboard-react/bootstrap/progress";
@import "black-dashboard-react/bootstrap/media";
@import "black-dashboard-react/bootstrap/list-group";
@import "black-dashboard-react/bootstrap/close";
@import "black-dashboard-react/bootstrap/modal";
@import "black-dashboard-react/bootstrap/tooltip";
@import "black-dashboard-react/bootstrap/popover";
@import "black-dashboard-react/bootstrap/carousel";
@import "black-dashboard-react/bootstrap/utilities";
@import "black-dashboard-react/bootstrap/print";
@import "black-dashboard-react/bootstrap/spinners";

// Custom components
@import "black-dashboard-react/custom/reboot.scss";
@import "black-dashboard-react/custom/global.scss";
@import "black-dashboard-react/custom/utilities.scss";
@import "black-dashboard-react/custom/grid-view.scss";
@import "black-dashboard-react/custom/alerts.scss";
@import "black-dashboard-react/custom/avatars.scss";
@import "black-dashboard-react/custom/badges.scss";
@import "black-dashboard-react/custom/buttons.scss";
@import "black-dashboard-react/custom/dropdown.scss";
@import "black-dashboard-react/custom/footer.scss";
@import "black-dashboard-react/custom/forms.scss";
@import "black-dashboard-react/custom/icons.scss";
@import "black-dashboard-react/custom/images.scss";
@import "black-dashboard-react/custom/modal.scss";
@import "black-dashboard-react/custom/navbar.scss";
@import "black-dashboard-react/custom/pagination.scss";
@import "black-dashboard-react/custom/type.scss";
@import "black-dashboard-react/custom/tables";
@import "black-dashboard-react/custom/checkboxes-radio";
@import "black-dashboard-react/custom/fixed-plugin";
@import "black-dashboard-react/custom/sidebar-and-main-panel.scss";
@import "black-dashboard-react/custom/misc.scss";
@import "black-dashboard-react/custom/rtl.scss";
@import "black-dashboard-react/custom/input-group.scss";

//Dev Defined Classes
@import "black-dashboard-react/custom/colors.scss";

// Vendor / Plugins

@import "black-dashboard-react/custom/vendor/plugin-perfect-scrollbar.scss";
@import "black-dashboard-react/custom/vendor/plugin-react-notification-alert.scss";

// light mode

@import "black-dashboard-react/custom/white-content.scss";

// Cards
@import "black-dashboard-react/custom/card";
@import "black-dashboard-react/custom/cards/card-chart";
@import "black-dashboard-react/custom/cards/card-map";
@import "black-dashboard-react/custom/cards/card-user";
@import "black-dashboard-react/custom/cards/card-task";
@import "black-dashboard-react/custom/cards/card-animation-on-hover";
@import "black-dashboard-react/custom/cards/card-collapse";
@import "black-dashboard-react/custom/cards/card-contributions";
@import "black-dashboard-react/custom/cards/card-lock";
@import "black-dashboard-react/custom/cards/card-pricing";
@import "black-dashboard-react/custom/cards/card-profile";
@import "black-dashboard-react/custom/cards/card-plain";
@import "black-dashboard-react/custom/cards/card-register";
@import "black-dashboard-react/custom/cards/card-stats";
@import "black-dashboard-react/custom/cards/card-subcategories";
@import "black-dashboard-react/custom/cards/card-testimonials";
@import "black-dashboard-react/custom/cards/card-wizard";
@import "black-dashboard-react/custom/views/faq";

// React differences
@import "black-dashboard-react/react-differences/react-differences";

@import "black-dashboard-react/landing-page.scss";

@import "./trade-pannel.scss";
@import "./react-grid-layout-styles.scss";
@import "./react-resizable-styles.scss";

.table-responsive {
  overflow: auto !important;
}

.coins-dropdown {
  max-height: 400px;
  overflow-y: auto;
  background: #1e1d2b;
}

.coins-dropdown::-webkit-scrollbar {
  display: none;
}

.page {
  height: 100%;
  position: relative;
  // background-color: red !important;

  .swap-card {
    p {
      color: #5fb49c !important;
      font-weight: bold;
    }
    // position: absolute;
    /* min-width: 500px;
        min-height: 500px; */
    // border-radius: 15px;
    // background-color: #222429;
    max-width: 500px;
    border-radius: 46px;
    background: #2b2b52;
    box-shadow: 12px 12px 24px #101015, -12px -12px 24px #2c2c37;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    padding: 0px 20px;
    padding-bottom: 20px;
    margin: 0 auto;
  }

  .swap--card__form {
    /* background-color: chocolate; */
    border: 1px solid rgba(255, 255, 255, 0.226);
    margin-top: 2rem !important;
    padding: 0.5rem 0.2rem;

    border-radius: 12px;
  }

  .swap--card__button {
    width: 95%;
    border-radius: 12px;
    background-image: none;
    background-color: #5fb49c !important;
    border-color: grey;
    padding: 15px 0px !important;
  }

  .form-label {
    font-size: large;
    font-weight: bolder;
    color: #77cbb9 !important;
  }

  input {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    padding-left: 0 !important;
    color: white !important;
    font-size: 1rem;
  }

  label {
    color: rgba(255, 255, 255, 0.377) !important;
  }
}

@keyframes RainbowLight {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.farming-card {
  max-width: 400px;
  padding: 2em;
  border: solid #b542d5 2px;
  border-radius: 15px;
}

.strategies-row {
  border: solid #38976d  2px;
  border-radius: 15px;
}

.investin-pulse-button {
  position: relative;
  display: block;
  // font-size: 1.3em;
  font-weight: light;
  font-family: "Trebuchet MS", sans-serif;
  text-transform: uppercase;
  text-align: center;
  // line-height: 100px;
  // letter-spacing: -1px;
  color: white;
  // border: none;
  // border-radius: 50%;
  // background: #5a99d4;
  cursor: pointer;
  box-shadow: 0px -2px 20px 5px #2630b2 !important;
  animation: pulse 1.5s infinite;
  -webkit-animation: pulse 1.5s infinite;
}

.pulse-button {
  position: relative;

  display: block;

  font-size: 1.3em;
  font-weight: light;
  font-family: "Trebuchet MS", sans-serif;
  text-transform: uppercase;
  text-align: center;
  line-height: 100px;
  letter-spacing: -1px;
  color: white;
  border: none;
  border-radius: 50%;
  // background: #5a99d4;
  cursor: pointer;
  box-shadow: 0px -2px 20px 5px #2630b2 !important;
  animation: pulse 1.5s infinite;
  -webkit-animation: pulse 1.5s infinite;
}
//   .pulse-button:hover {
//     animation : pulse 1.5s infinite;
//     -webkit-animation: none;
//   }

@keyframes pulse {
  0% {
    transform: (scale(0.9));
  }
  70% {
    transform: (scale(1));
    box-shadow: 0 0 0 50px rgba(#5a99d4, 0) !important;
  }
  100% {
    transform: (scale(0.9));
    box-shadow: 0 0 0 0 rgba(#5a99d4, 0) !important;
  }
}

.link-funds {
  color: white;
  font-weight: 600;
}

.link-funds:hover {
  color: white;
  text-decoration: underline;
}

.link-funds:visited{
  color: white;
  text-decoration: none;
}


.trade-table {
  .MuiPaper-root {
    background-color: #151839 !important;
    color: white;
  }

  .MuiTableHead {
    background-color: #151839 !important;
  }

  .MuiTablePagination-caption,
  .MuiTypography-caption,
  .MuiIconButton-root,
  .MuiTableCell-body {
    color: white !important;
  }

  .MTableToolbar-root,
  .MuiTableCell-head,
  .MTableToolbar-searchField,
  .MuiInputBase-adornedEnd {
    background-color: #151839 !important;
    color: white !important;
  }

  .MTableToolbar-title-27 {
    .MuiTypography-h6 {
      font-size: 1rem !important;
    }
  }

  .MuiTableCell-root {
    border-bottom: solid 1px rgba(255, 255, 255, 0.1) !important;
  }

  .MuiTablePagination-toolbar {
    color: white !important;
  }
}

.trade-theme {
  padding: 5px;
  border-radius: 10px !important;
}

.approve-button {
  background-color: none;
  background-image: none;
  background-color: #3f55d4 !important;
}

.style-7::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px !important;
}

.style-7::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 10px !important;
}

.style-7::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, #b442d47c),
    color-stop(0.72, #b442d47c),
    color-stop(0.86, #b442d47c)
  );
}

.swapLogo {
  img {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    width: 3rem;
    // height: 2rem;
    height: auto;
    // background-color: #B442D4;
  }
}

.strategy-card{
    // max-width: 400px;
    padding: 1em;
    border: solid #b542d5 2px;
    border-radius: 15px;
}

.strategy-col{
    text-align: center; 
    padding: 1% !important;
    min-width: fit-content;

    p {
      font-weight: 700;
  }
}

.strategy-col-heading{
    font-size: 15px !important;
     margin-top: 4px !important;
     color: #ffffffa6;
}


.strategy-sub-container-box{
  text-align: center;
 padding: 1.5rem ;
  border-radius: 12px ;
  border: solid 0.1px rgb(0,70,113);
}

.strategy-input{
  text-align: center;
 margin-top: 5% ;
  border: solid 0.1px rgb(0,70,113);
}

.protocol-logo{
  margin: 5px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.strategy-logo{
  margin: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.strategy-name {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 2px;
  color: white;
  text-align: right;
  margin-right: 10px;
}
.strategy-name:hover {
  color: white;
  text-decoration: underline;
}
.strategy-name:visited {
  color: white;
  text-decoration: none;
}

.logo-top-right{
  position: absolute !important; 
  left: 25% !important;
}

.st-name-col{
  text-align: right;
}

@media only screen and (max-width: 550px) {
  .strategy-logo {
    display: none;
  }
  .strategy-name {
   text-align: center;
  }
  .st-name-col {
    text-align: center;
  }

  .strategy-sub-container-box {
    margin-bottom: .5rem;
  }
}

.TVChartContainer {
  height: 100%;
}

.react-resizable-handle-se {
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.testing {
  padding-top: 20px;
  padding-left: 10px;
  background-color: #101334;

  .tabs .tab-header {
    height: 60px;
    display: flex;
    align-items: center;
    z-index: 0;
  }
  .tabs .tab-header > div {
    // width: calc(50% / 4);
    text-align: center;
    color: #888;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    outline: none;
    padding: 1rem;
  }
  .tabs .tab-header > div > i {
    display: block;
    margin-bottom: 5px;
  }
  .tabs .tab-header > div.active {
    color: #b442d4;
    transition: all 100ms ease-in-out;

    border-bottom: 2px solid #b442d4;
    // padding: 1rem 0;
  }

  .tabs .tab-body {
    position: relative;
    // height: calc(100% - 60px);
    // width: 100%;
    padding: 10px 5px;
    height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .tabs .tab-body > div {
    position: absolute;
    width: 100%;
    top: -200%;
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 500ms ease-in-out 0ms, transform 500ms ease-in-out 0ms;
  }
  .tabs .tab-body > div.active {
    top: 0px;
    opacity: 1;
    transform: scale(1);
  }

  table tr td {
    color: white;
    padding: 13px 8px;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
  }

  th {
    color: white;
    padding: 13px 8px;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
  }

  tr:hover {
    background-color: rgba(0, 0, 255, 0.363);
  }

  @media (max-width: 575.98px) {
    table {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    table tr td {
      color: white;
      padding: 13px 8px;
      font-size: 13px;
      font-weight: 300;
      text-align: center;
    }
  }

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    table,
    .tab-body {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    table tr td {
      color: white;
      padding: 13px 8px;
      font-size: 13px;
      font-weight: 300;
      text-align: center;
    }
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
  }
}

.modal {
  background-color: rgba(3, 3, 3, 0.473);
}

.mangoManageModal {
  background-color: rgba(61, 61, 61, 0.473);
  .modal-title {
    color: white !important;
  }

  .modal-body {
    text-align: center;

    .input {
      position: relative;

      &__label {
        position: absolute;
        left: 0;
        top: 0;
        padding: calc(0.5rem * 0.75) calc(0.5rem * 0.5);
        margin: calc(0.5rem * 0.75 + 3px) calc(0.5rem * 0.5);
        // background: pink;
        white-space: nowrap;
        transform: translate(0, 0);
        transform-origin: 0 0;
        // background: white;
        transition: transform 120ms ease-in;
        font-weight: bold;
        line-height: 1.2;
      }
      &__field {
        box-sizing: border-box;
        display: block;
        width: 100%;
        border: 1.5px solid rgba(255, 255, 255, 0.555);
        padding: calc(0.5rem * 1.5) 0.5rem;
        color: rgba(255, 255, 255, 0.555);
        background: transparent;
        border-radius: 4px;

        &:focus,
        &:not(:placeholder-shown) {
          & + .input__label {
            transform: translate(0.25rem, -65%) scale(0.8);
            color: #fab700;
          }
        }
      }
    }

    p {
      margin: 0 1rem;
    }
  }
}

.confetti-container {
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;

  .investin-confetti {
    position: relative;
    top: 55vh;
    left: 50vw;
  }
}

.react-select__control {
  background: #141939 !important;
  border-color: #7237a2 !important;
  border-radius: 20px !important;

  .react-select__multi-value,
  .react-select__multi-value__remove {
    border-radius: 20px;
  }
}

.checkmark {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: inline-block;
  stroke-width: 5;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

.withdraw-investment-steps {
  color: white;
  padding: 0.5rem;
  svg {
    margin-right: 0.3rem;
    margin-left: 0.3rem;
  }

  .chevron-right-icon {
    margin-right: 0rem;
  }

  .withdraw-spinner {
    margin-right: 0.3rem;
    margin-left: 0.3rem;
  }
}

@media (max-width: 450px) {
  .withdraw-investment-steps {
    div {
      max-width: 4rem;
      text-align: center;
    }

    div > svg {
      display: block;
      margin: 0 auto;
    }
  }
}

.startFundModal {
  transform: translate(0, 2%) !important;
}

.beta-banner {
  background-color: #151839;
  border-top: 2px solid #b442d4;
  border-radius: 5px;
}
// .neon {
//   text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #49ff18, 0 0 30px #49ff18, 0 0 40px #49ff18,
//     0 0 55px #49ff18, 0 0 75px #49ff18, -40px -40px 0px rgba(206, 89, 55, 0);
// }

.green-odometer {
  span,
  .odometer-value,
  .odometer-formatting-mark {
    color: #55ff00 !important;
    text-shadow: 0 0 5px rgba(85, 255, 0, 0.7), 0 0 20px rgba(85, 255, 0, 0.7), 0 0 60px rgba(85, 255, 0, 0.7);
  }
}

.red-odometer {
  span,
  .odometer-value,
  .odometer-formatting-mark {
    color: #ff0000 !important;
    text-shadow: 0 0 5px rgba(255, 0, 0, 0.7), 0 0 20px rgba(255, 0, 0, 0.7), 0 0 60px rgba(255, 0, 0, 0.7);
  }
}

// .red-neon {
//   color: #ff0000 !important;
//   font-weight: 600;
//   text-shadow: 0 0 5px rgba(255, 0, 0, 0.7), 0 0 20px rgba(255, 0, 0, 0.7), 0 0 60px rgba(255, 0, 0, 0.7);
// }

// .green-neon {
//   color: #55ff00 !important;
//   font-weight: 600;
//   text-shadow: 0 0 5px rgba(85, 255, 0, 0.7), 0 0 20px rgba(85, 255, 0, 0.7), 0 0 60px rgba(85, 255, 0, 0.7);
// }

.bold {
  font-weight: 600;
}

#custom-css-outlined-input-label {
  color: white;

  .MuiOutlinedInput-notchedOutline {
    border-color: #3F55D4 !important;
  }
}

// ====================toggle button on strategies page =====

input[type=checkbox] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-tap-highlight-color: transparent;
	cursor: pointer;
}
input[type=checkbox]:focus {
	outline: 0;
}
.toggle-staked {
	height: 28px;
	width: 48px;
	border-radius: 16px;
	display: inline-block;
	position: relative;
	margin: 0;
	border: 2px solid #474755;
	background: linear-gradient(180deg, #2D2F39 0%, #1F2027 100%);
	transition: all 0.2s ease;
	&:after {
		content: "";
		position: absolute;
		top: 2px;
		left: 2px;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: white;
		box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
		transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
	}
	&:checked {
		border-color: #654FEC;
		&:after {
			transform: translatex(20px);
		}
	}
}

// ====================filter dropdown on strategies page =====
// https://codepen.io/aybukeceylan/pen/PopNYeJ
.action-button {
  border-radius: 4px;
  height: 45px;
  width : 110%;
  background-color: #1d283c;
  border: 1px solid #1d283c;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px; //12px
  margin-left: 8px;
  cursor: pointer;
}
.action-button span {
  margin-right: 4px;
}
.action-button:hover {
  border-color:#6291fd;
}
.action-button:focus, .action-button.active {
  outline: none;
  color: #2869ff;
  border-color: #2869ff;
}

.app-content-actions-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.filter-button-wrapper {
  position: relative;
}

.filter-menu {
  background-color: #1d283c;
  position: absolute;
  top: calc(100% + 16px);
  right: -74px;
  border-radius: 4px;
  padding: 8px;
  width: 230px; //220px
  z-index: 2;
  box-shadow: rgba(16, 24, 39, 0.8) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s;
}
.filter-menu:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid  #1d283c;
  bottom: 100%;
  left: 50%;
  transform: translatex(-50%);
}
.filter-menu.active {
  visibility: visible;
  opacity: 1;
  top: calc(100% + 8px);
}
.filter-menu label {
  display: block;
  font-size: 16px;//14px
  color:  #fff;
  margin-bottom: 8px;
}
.filter-menu select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  padding: 8px 24px 8px 8px;
  background-position: right 4px center;
  border: 1px solid  #fff;
  border-radius: 4px;
  color:  #fff;
  font-size: 14px; //12px
  background-color: transparent;
  margin-bottom: 16px;
  width: 100%;
}
.filter-menu select option {
  font-size: 16px; //14px
}
.light .filter-menu select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%231f1c2e' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
}
.filter-menu select:hover {
  border-color: #6291fd;
}
.filter-menu select:focus, .filter-menu select.active {
  outline: none;
  color: #2869ff;
  border-color: #2869ff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%232869ff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
}

.filter-menu-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-button {
  border-radius: 2px;
  font-size: 14px;
  padding: 4px 8px;
  cursor: pointer;
  border: none;
  color: #fff;
}
.filter-button.apply {
  background-color: #2869ff;
}
.filter-button.reset {
  background-color: #2c394f;
}

// ====================filter dropdown on strategies page =====

//=========== investin-deposit-modal==========
.investinDepositModal {
  .modal {
    vertical-align: middle;
  }

  .modal-content {
    background-color: #1a1838;
    color: white;
    // width: 150%;
  }

  .modal-header {
    border-top: 5px solid #b409d4;
    border-bottom: none;
    .close {
      color: white;
    }
  }

  .max-input {
    border-right: none;
  }

  .select-max {
    font-size: 0.8rem;
    background-color: white;
    border-left: none;
  }

  

  .modal-footer {
    display: block;
    text-align: center;
    border-top: none;
    .btn {
      background-color: #b409d4;
      border: none;
    }
  }
}

.desposit-center-button{
  border: none;
  border-radius: 5px;
}

.deposit-options {
    display: flex;
    justify-content: center;
    margin-top: 6%;
  }

.btn-deposit {
    background-color: transparent !important;
    width: 15rem;
    margin-right: 0.5rem;
    font-weight: bolder;
  }

  .img-button {
    background-color: transparent;
    border: none;
  }
//=========== investin-deposit-modal==========

.MarketMakingConfigModal {
   .modal-content {
     @media screen and (min-width: 991px) {
        width: 130%;
      }
  }
}


.user-strategies {
  .card-title {
    b {
      @media screen and (max-width: 991px) {
        font-size: 1.6rem !important;
      }
    }
  }
  .search-and-filter {
    padding: 0 1rem;

    @media screen and (max-width: 991px) {
      >div:nth-child(1) {
        order: 1;
        
        p {
          font-size: 0.7rem !important;
          display: flex;
          align-items: center;
          margin: 0;
        }
      }
      >div:nth-child(2) {
        width: 100%;
        margin-top: 1rem;
        order: 3;
      }
      >div:nth-child(3) {
        order: 2;

        .action-button {
          height: 35px;
        }
      }
    }

    .toggle {
      p {
        font-size: 1.09rem;
        font-weight: 600;
        color : white;
      }
    }
    .action-button {
      width: 100%;
      margin-left: unset;
    }
    .search {
      .search-bar {
        margin-left: 0;
        height: 35px;
      }

      .content {
        padding: 0;
      }
    }

    .filter-menu {
      right: 0px;
      z-index: 11;

      &:before {
        left: 76%;
      }
    }
  }

  .nav-pills-info {
    .background-main {
      @media screen and (max-width: 991px) {
        padding: 0 !important;

        .nav-link {
          min-width: 80px;
          padding: 8px 0;
        }
      }
      .nav-link {
        margin-right: unset !important;
  
        &.active {
          // box-shadow: unset !important;
          // background: #2630B2 !important;
        }
      }
    }
  }

  .tab-space {
    // padding-bottom: 20px;

    .show-mobile {
      display: none;
    }

    @media screen and (max-width: 991px) {
      .strategies-container {
        .card-header {
          padding: 10px 10px 0;

          .protocol-icon {
            min-height: 30px;
            min-width: 30px;
            max-height: 30px;
            max-width: 30px;
            margin-right: .5rem;
            padding: 0;

            .protocol-logo {
              height: unset;
              width: 100%;
              max-width: 100%;
              margin: unset;
            }

            img {
              max-height: 100%;
            }
          }

          .strategy-col {
            padding: 0 !important;
            max-width: 100%;

            &.protocal-name {
              // display: flex;
              flex: 2;
              justify-content: center;
              align-items: center;
            }
            &.expand-btn {
              max-width: 40px;

              button {
                min-width: 20px;
                padding: 5px;
              }
            }
            .row {
              margin: 0;
            }
            .st-name-col {
              text-align: left;
              .strategy-name {
                font-size: 13px;
              }
            }
            .strategy-col-heading {
              font-size: 11px !important;
            }
            .loader {
              font-size: 11px;
              svg {
                width: 40px;
              }
            }
          }
        }

        >div:first-child {
          .strategy-card {
            // border-radius: 30px 30px 0 0;
          }
        }
        .strategy-card {
          >div {
            >div {
              flex: 1;
            }
          }
          // margin: 0;
          // border-width: 0 0 2px 0;
          // border-radius: unset;
        }
      }

      .hide-mobile {
        display: none;
      }
      .show-mobile {
        display: flex;
        margin-bottom: 1rem;
        .strategy-col {
          flex: 1;
          .strategy-col-heading {
            font-size: 11px !important;
          }
          .loader {
            font-size: 11px;
            svg {
              width: 40px;
            }
          }
        }
      }
    }
  }

}
 
/* start-fund TOGGLE STYLING 
 https://codepen.io/dsenneff/pen/ZoLVZW
*/
$darkNavy: #213140;
$teal1: #66B3FB;
$teal2: #4B9DEA;
$charcoal: #555555;
$gold: #B6985A;
$activeShadow: 0 0 10px rgba($teal1, .5);

@mixin focusOutline {outline: dotted 1px #CCC; outline-offset: .45rem;}
@mixin hideInput {width: 0; height: 0; position: absolute; left: -9999px;}
@mixin breakpoint($point) {
	@if $point == 1100 {
		@media (max-width: 1100px) { @content ; }
	}
	@else if $point == 800 {
		@media (max-width: 800px) { @content ; }
	}
}


.start-fund-toggle {
	margin: 0 0 1.5rem; box-sizing: border-box;
	font-size: 0;
	display: flex; flex-flow: row nowrap;
	justify-content: flex-start; align-items: stretch;
	input {@include hideInput;}
	input + label {
    cursor: pointer;
		margin: 0; padding: .75rem 2rem; box-sizing: border-box;
		position: relative; display: inline-block;
     color : black;
		border: solid 1px #DDD; background-color: #FFF;
		font-size: 0.95rem; line-height: 140%; font-weight: 600; text-align: center;
		box-shadow: 0 0 0 rgba(255,255,255,0);
		transition: 	border-color .15s ease-out, 
					color .25s ease-out, 
					background-color .15s ease-out,
					box-shadow .15s ease-out;
		
		/* ADD THESE PROPERTIES TO SWITCH FROM AUTO WIDTH TO FULL WIDTH */
		/*flex: 0 0 50%; display: flex; justify-content: center; align-items: center;*/
		/* ----- */
		
		&:first-of-type {border-radius: 6px 0 0 6px; border-right: none;}
		&:last-of-type {border-radius: 0 6px 6px 0; border-left: none;}
	}
	input:hover + label {border-color: $darkNavy;}
	input:checked + label {
		background-color: $teal2;
		color: #FFF;
		box-shadow: $activeShadow;
		border-color: $teal2;
		z-index: 1;
	} 

	// input:focus + label {@include focusOutline;}

	@include breakpoint(800) {
		input + label {
			padding: .75rem .25rem;
			flex: 0 0 50%;
			display: flex; justify-content: center; align-items: center;
		}
	}
}
//  start-fund TOGGLE STYLING END ======


.no-investments-overlay-button {

    position: 'absolute';
    top: '50%';
    left: '40%';


   @media screen and (max-width: 425px) {
      top: '50% !important';
      left: '25% !important';
   }
}